import { render, staticRenderFns } from "./BaseGalleriaEvento.vue?vue&type=template&id=57e0243e&scoped=true&"
import script from "./BaseGalleriaEvento.vue?vue&type=script&lang=js&"
export * from "./BaseGalleriaEvento.vue?vue&type=script&lang=js&"
import style0 from "./BaseGalleriaEvento.vue?vue&type=style&index=0&id=57e0243e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57e0243e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VIcon,VImg,VProgressCircular})
