var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-scheda',{attrs:{"models-name":"eventi","schede":_vm.schede},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var model = ref.model;
return [_c('scheda-evento-header',{attrs:{"model":model},on:{"finish":function($event){_vm.isLingueLoading = false}},model:{value:(_vm.idLinguaFk),callback:function ($$v) {_vm.idLinguaFk=$$v},expression:"idLinguaFk"}})]}},{key:"titolo",fn:function(ref){
var model = ref.model;
return [(_vm.isLingueLoading)?_c('loading-resouces',{staticClass:"pt-4",attrs:{"icon":"fa-flag","messaggio":"Recupero delle lingue in corso"}}):(Boolean(_vm.idLinguaFk))?_c('scheda-evento-titolo',{attrs:{"model":model,"testo":_vm.getTesto(model)}}):_c('no-data-available',{attrs:{"models-name":"lingue","type":"lingue"}})]}},(Boolean(_vm.idLinguaFk))?{key:"generale",fn:function(ref){
var model = ref.model;
return [_c('scheda-evento-generale',{attrs:{"model":model,"testo":_vm.getTesto(model),"id-lingua-fk":_vm.idLinguaFk}})]}}:null,(Boolean(_vm.idLinguaFk))?{key:"testo",fn:function(ref){
var model = ref.model;
return [_c('scheda-evento-testo',{attrs:{"model":model,"testo":_vm.getTesto(model)}})]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }