<template>
    <base-scheda-generale :model="model" :items="items"></base-scheda-generale>
</template>

<script>
    import BaseSchedaGenerale from '@/components/aaaProject/scheda/BaseSchedaGenerale';
    import moment from '@/moment';

    export default {
        name: 'SchedaEventoGenerale',
        components: {BaseSchedaGenerale},
        props: {model: Object, testo: Object, idLinguaFk: Number},
        computed: {
            items: function () {
                return [
                    {label: 'Titolo', data: this.testo.TITOLO},
                    {label: 'Dettaglio', data: this.testo.DETTAGLIO},
                    {label: 'Cliente', data: this.model.CL_NOME},
                    {label: 'Taglia', data: this.model.TAGLIA},
                    {label: 'Citta', data: this.citta + ' (' + this.model.CT_SIGLA + ')'},
                    {label: 'Indirizzo', data: this.model.INDIRIZZO},
                    {label: 'Tipologia', data: this.tipologia},
                    {label: 'Percorso', data: !this.model.IS_PERCORSO ? 'NO' : 'SI'},
                    {label: 'Durata', data: this.model.DURATA},
                    {label: 'Data', data: this.dataEvento},
                    {label: 'Permanenza', data: this.permanenza},
                    {label: 'Coordinate', data: this.model.COORDINATE},
                    {label: 'Testi Associati', data: this.testiAssociati},
                    {label: 'Gallerie', data: this.model.EVG_NORM_COUNT + ' (+' + this.model.EVG_LAVO_COUNT + ')'},
                    {label: 'Visualizzazioni', data: this.model.VISUALIZZAZIONI},
                    {
                        label: 'Disattivazione Manuale',
                        data: this.model.DATA_DISATTIVAZIONE ? moment.toDate(this.model.DATA_DISATTIVAZIONE) : 'MAI'
                    },
                    {label: 'empty', noLabel: true, data: ' '}
                ]
            },
            testiAssociati: function () {
                return this.model.testi.length + ' ( ' + this.model.testi.map(t => t.LNG_SIGLA).join(', ') + ' )';
            },
            tipologia: function () {

                let testo = this.model.tipologia.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = this.model.tipologia.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = this.model.tipologia[0];
                }

                return testo.NOME;

            },
            citta: function () {

                let testo = this.model.citta.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = this.model.citta.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = this.model.citta[0];
                }

                return testo.DENOMINAZIONE;

            },
            dataEvento: function () {

                if (this.model.DURATA === 'STATICO') {

                    return 'Sempre Disponibile'

                } else if (this.model.DURATA === 'SINGOLO') {

                    return moment.toDate(this.model.DATA_INIZIO, 'L [dalle] LTS');

                }

                return 'dal ' + moment.toDate(this.model.DATA_INIZIO, 'L') +
                       ' al ' + moment.toDate(this.model.DATA_FINE, 'L');

            },
            permanenza: function () {
                return this.model.PERMANENZA ? this.model.PERMANENZA + ' minuti' : '-';
            }
        }
    }
</script>

<style scoped>

</style>
