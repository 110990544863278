<template>
    <base-scheda-attributo titolo="Incarichi">

        <h-col v-if="isLoading" cols="11" sm="10">
            <loading-resouces icon="fa-tasks" messaggio="Recupero incarichi in corso"></loading-resouces>
        </h-col>

        <h-col v-else cols="11" sm="10">

            <base-form-field-api-select v-show="false" models-name="utenti"></base-form-field-api-select>

            <h-row v-for="(t, index) in types" :key="index" align="center" class="pb-6">

                <h-col cols="12" sm="4" lg="2" class="pb-0 pb-sm-3">
                    <h2 class="text-center">{{ t }}</h2>
                </h-col>

                <h-col v-if="!!getIncarico(t).ID_REC" cols="12" sm="8" lg="10">
                    <span>
                        Assegnato a <strong>{{ getIncarico(t).UT_NOMINATIVO }}</strong>
                        da <i>{{ getIncarico(t).CREATOR }}</i>
                        il {{ getData(getIncarico(t).CREATOR_TIMESTAMP) }}
                    </span>
                </h-col>

                <h-col v-else cols="12" sm="8" lg="10">
                    <h-row>

                        <h-col cols="12" sm="8" md="10" no-padding>
                            <base-select-field v-model="getIncarico(t).ID_UTENTE_FK"
                                               :items="utenti"
                                               label="Utente"
                                               placeholder="Seleziona un utente"
                                               item-text="EMAIL"
                                               item-value="ID_REC"
                                               :disabled="!!isGlobalLoading"
                            ></base-select-field>
                        </h-col>

                        <h-col cols="12" sm="4" md="2" no-padding>
                            <button-tooltip block
                                            text="ASSEGNA"
                                            color="success"
                                            min-height="100%"
                                            :disabled="!!isGlobalLoading || !getIncarico(t).ID_UTENTE_FK"
                                            tooltip="Click per assegnare l'incarico all'utente"
                                            @click.stop="onAssegnaIncarico(getIncarico(t))"
                            ></button-tooltip>
                        </h-col>

                    </h-row>
                </h-col>

            </h-row>
        </h-col>

    </base-scheda-attributo>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseSchedaAttributo from '@/components/aaaProject/scheda/BaseSchedaAttributo';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import BaseSelectField from '@/components/aaaGenerics/form/BaseSelectField';
    import moment from '@/moment';
    import BaseFormFieldApiSelect from "@/components/aaaProject/form/BaseFormFieldApiSelect";

    export default {
        name: 'SchedaEventoIncarichi',
        components: {
            BaseFormFieldApiSelect,
            HRow,
            HCol,
            ButtonTooltip,
            LoadingResouces,
            BaseSchedaAttributo,
            BaseSelectField
        },
        props: {model: Object},
        data: () => ({
            apiTimer: false,
            isLoading: true,
            types: ['BASE', 'TESTI', 'GALLERIA', 'LINK', 'GPX'],
            incarichi: []
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            utenti: function () {
                return this.$store.state.apiModule.utenti;
            }
        },
        methods: {
            loadIncarichi() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET del modello
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_EVENTO_INCARICHI',
                            paylod: {idEvento: _this.model.ID_REC},
                            doResponse: incarichi => _this.incarichi = incarichi,
                            doClose: isOk => _this.isLoading = !isOk
                        });
                    }
                }

                this.isLoading = true;
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);

            },
            onAssegnaIncarico(incarico) {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //PUT di un incarico
                        _this.$store.dispatch('defaultRequest', {
                            api: 'PUT_EVENTO_INCARICO',
                            paylod: {
                                idEvento: _this.model.ID_REC,
                                incarico
                            },
                            doResponse: response => {

                                incarico.ID_REC = response.ID_REC;
                                incarico.UT_NOMINATIVO = response.UT_NOMINATIVO;
                                incarico.CREATOR = response.CREATOR;
                                incarico.CREATOR_TIMESTAMP = response.CREATOR_TIMESTAMP;

                                _this.$store.dispatch('activeSnackbar', {
                                    color: 'success',
                                    text: 'Incarico [' + incarico.TYPE + '] assegnato con successo'
                                });

                            },
                            doClose: () => {
                            }
                        });
                    }
                }

                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);

            },
            getIncarico(t) {

                let inc = this.incarichi.find(i => i.TYPE === t);

                if (!inc) {
                    this.incarichi.push({TYPE: t});
                    inc = this.getIncarico(t);
                }

                return inc;

            },
            getData(data) {
                return moment.toDate(data);
            }
        },
        created() {
            this.loadIncarichi();
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
