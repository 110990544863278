<template>
    <h-row style="height: 200px;" class="row-container" justify="center" align="center">

        <h-col v-if="!Boolean(galleria)" cols="auto">
            <v-icon x-large color="error" style="font-size: 100px;">fas fa-exclamation-triangle</v-icon>
        </h-col>

        <h-col v-else cols="12" no-padding>

            <h-row class="row-top" justify="space-around" align="center">

                <v-icon small :color="galleria.IS_COVER ? 'success' : ( galleria.IS_ON_TOP ? 'warning' : 'error' )">
                    {{ (galleria.IS_COVER || galleria.IS_ON_TOP) ? 'fa-star' : 'far fa-star' }}
                </v-icon>

                <i>{{ NOME.length > 17 ? NOME.substr(0, 17) + '...' : NOME.substr(0, 17) }}</i>

                <v-icon small>
                    {{ galleria.IS_VIDEO ? 'fa-video' : 'fa-image' }}
                </v-icon>

            </h-row>

            <h-row class="row-body" justify="center" align="center" :style="!!cover ? 'cursor: pointer;' : ''">

                <v-progress-circular v-if="isLoading" indeterminate size="100"></v-progress-circular>

                <v-img v-else-if="Boolean(cover)"
                       width="100%"
                       max-height="100%"
                       :src="cover"
                       :alt="galleria.NOME"
                       @click.stop="onOpenDialog"
                ></v-img>

                <v-icon v-else-if="working"
                        x-large
                        color="primary"
                        style="font-size: 100px;"
                >
                    fas fa-wrench
                </v-icon>

                <v-icon v-else
                        x-large
                        color="primary"
                        style="font-size: 100px;"
                        @click.stop="loadCover"
                >
                    fas fa-download
                </v-icon>

            </h-row>

        </h-col>

    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'BaseGalleriaEvento',
        components: {HRow, HCol},
        props: {galleria: Object, working: Boolean, reset: Boolean},
        data: () => ({
            isLoading: false,
            apiTimer: false,
            cover: null,
            uri: null
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            NOME: function () {
                return !this.galleria.NOME ? '' : this.galleria.NOME;
            }
        },
        watch: {
            reset: function () {

                if (this.cover) {
                    window.URL.revokeObjectURL(this.cover);
                    this.cover = null;
                }

                if (this.uri) {
                    window.URL.revokeObjectURL(this.uri);
                    this.uri = null;
                }

            }
        },
        methods: {
            loadCover() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET della cover/immagine della galleria
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_EVENTO_GALLERIA',
                            paylod: {
                                idEvento: _this.galleria.ID_EVENTO_FK,
                                idGalleria: _this.galleria.ID_REC,
                                COVER: true
                            },
                            doResponse: media => _this.cover = URL.createObjectURL(media),
                            doClose: () => _this.isLoading = false
                        });
                    }
                }

                if (this.galleria.ID_REC) {

                    this.isLoading = true;
                    this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                    doRequest(this);

                } else if (this.galleria.IS_VIDEO && !!this.galleria.COVER_FILE) {

                    this.cover = URL.createObjectURL(this.galleria.COVER_FILE);

                } else if (!this.galleria.IS_VIDEO && !!this.galleria.MEDIA_FILE) {

                    this.cover = URL.createObjectURL(this.galleria.MEDIA_FILE);

                }

            },
            loadVideo() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET del video della galleria
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_EVENTO_GALLERIA',
                            paylod: {
                                idEvento: _this.galleria.ID_EVENTO_FK,
                                idGalleria: _this.galleria.ID_REC,
                                COVER: false
                            },
                            doResponse: media => {

                                _this.uri = URL.createObjectURL(media);

                                window.open(_this.uri);

                            },
                            doClose: () => _this.isLoading = false
                        });
                    }
                }

                if (this.uri) {
                    return window.open(this.uri);
                }

                if (this.galleria.ID_REC) {

                    this.isLoading = true;
                    this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                    doRequest(this);

                } else if (this.galleria.MEDIA_FILE) {

                    this.uri = URL.createObjectURL(this.galleria.MEDIA_FILE);

                    window.open(this.uri);

                }

            },
            onOpenDialog() {

                if (this.galleria.IS_VIDEO) {
                    return this.loadVideo();
                }

                window.open(this.cover);

            }
        },
        beforeDestroy() {

            window.clearInterval(this.apiTimer);

            if (this.cover) {
                window.URL.revokeObjectURL(this.cover);
            }

            if (this.uri) {
                window.URL.revokeObjectURL(this.uri);
            }

        }
    }
</script>

<style scoped>

    .row-container {
        padding: 0;
        width: 200px;
        height: 200px;
        margin: 5px;
        border: 1px solid dimgray;
        border-radius: 4px;
    }

    .row-top {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 20px;
    }

    .row-body {
        margin: 0;
        padding: 5px;
        width: 100%;
        height: 180px;
    }

    .row-button {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 50px;
    }

</style>
