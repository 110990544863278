<template>
    <base-scheda-titolo models-name="eventi"
                        :model="model"
                        model-ref="l'evento"
                        :titolo="!!testo.TITOLO ? testo.TITOLO + ' (' + testo.LNG_SIGLA + ')' : '-'"
                        :can-edit="$store.state.authModule.utente.CAN_EDIT_EVENTO"
                        :can-delete="$store.state.authModule.utente.CAN_DELETE_EVENTO"
                        check-text="Elimina anche tutti i testi, media e links associati"
    >
        <template #pre-titolo>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="!model.IS_SUGGERITO ? 'error' : 'success'" large>
                        {{ !model.IS_SUGGERITO ? 'far fa-gem' : 'fa-gem' }}
                    </v-icon>
                </template>
                <span>
                    {{ 'L\'evento ' + (!model.IS_SUGGERITO ? 'NON ' : '') + 'risulta aggiunto ai tesori nascosti' }}
                </span>
            </v-tooltip>
        </template>
    </base-scheda-titolo>
</template>

<script>
    import BaseSchedaTitolo from '@/components/aaaProject/scheda/BaseSchedaTitolo';

    export default {
        name: 'SchedaEventoTitolo',
        components: {BaseSchedaTitolo},
        props: {model: Object, testo: Object}
    }
</script>

<style scoped>

</style>
