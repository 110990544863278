<template>
    <base-scheda-attributo titolo="Testo">

        <h-col v-if="!!testo && !!testo.TESTO" cols="11" sm="10">
            <v-textarea solo
                        :value="testo.TESTO"
                        full-width
                        counter
                        auto-grow
                        readonly
            ></v-textarea>
        </h-col>

        <no-data-available v-else type="galleria"></no-data-available>

    </base-scheda-attributo>
</template>

<script>
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';
    import BaseSchedaAttributo from '@/components/aaaProject/scheda/BaseSchedaAttributo';

    export default {
        name: 'SchedaEventoTesto',
        components: {HCol, NoDataAvailable, BaseSchedaAttributo},
        props: {model: Object, testo: Object},
    }
</script>

<style scoped>

</style>
