<template>
    <base-scheda models-name="eventi" :schede="schede">

        <template #header="{ model }">
            <scheda-evento-header :model="model"
                                  v-model="idLinguaFk"
                                  @finish="isLingueLoading = false"
            ></scheda-evento-header>
        </template>

        <template #titolo="{ model }">

            <loading-resouces v-if="isLingueLoading"
                              class="pt-4"
                              icon="fa-flag"
                              messaggio="Recupero delle lingue in corso"
            ></loading-resouces>

            <scheda-evento-titolo v-else-if="Boolean(idLinguaFk)"
                                  :model="model"
                                  :testo="getTesto(model)"
            ></scheda-evento-titolo>

            <no-data-available v-else models-name="lingue" type="lingue"></no-data-available>

        </template>

        <template #generale="{ model }" v-if="Boolean(idLinguaFk)">
            <scheda-evento-generale :model="model"
                                    :testo="getTesto(model)"
                                    :id-lingua-fk="idLinguaFk"
            ></scheda-evento-generale>
        </template>

        <template #testo="{ model }" v-if="Boolean(idLinguaFk)">
            <scheda-evento-testo :model="model"
                                 :testo="getTesto(model)"
            ></scheda-evento-testo>
        </template>

    </base-scheda>
</template>

<script>
    import BaseScheda from '@/components/aaaProject/scheda/BaseScheda';
    import SchedaEventoHeader from '@/components/secure/eventi/components/SchedaEventoHeader';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import SchedaEventoTitolo from '@/components/secure/eventi/components/SchedaEventoTitolo';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';
    import SchedaEventoGenerale from '@/components/secure/eventi/components/SchedaEventoGenerale';
    import SchedaEventoTesto from '@/components/secure/eventi/components/SchedaEventoTesto';
    import SchedaEventoGalleria from '@/components/secure/eventi/components/SchedaEventoGalleria';
    import SchedaEventoLinks from '@/components/secure/eventi/components/SchedaEventoLinks';
    import SchedaEventoIncarichi from '@/components/secure/eventi/components/SchedaEventoIncarichi';

    export default {
        name: 'SchedaEvento',
        components: {
            SchedaEventoTesto,
            SchedaEventoGenerale,
            SchedaEventoTitolo,
            SchedaEventoHeader,
            LoadingResouces,
            NoDataAvailable,
            BaseScheda
        },
        data: (_this) => ({
            isLingueLoading: true,
            idLinguaFk: undefined,
            schede: [
                {nome: 'testo', component: SchedaEventoTesto, showIf: () => Boolean(_this.idLinguaFk)},
                {nome: 'galleria', component: SchedaEventoGalleria, showIf: () => Boolean(_this.idLinguaFk)},
                {nome: 'links', component: SchedaEventoLinks, showIf: () => Boolean(_this.idLinguaFk)},
                {
                    nome: 'incarichi',
                    component: SchedaEventoIncarichi,
                    showIf: () => Boolean(_this.idLinguaFk) && !!_this.$store.state.authModule.utente.CAN_UTENTE
                }
            ]
        }),
        methods: {
            getTesto(evento) {

                if (!evento.testi || evento.testi.length <= 0) {
                    return {};
                }

                let testo = evento.testi.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = evento.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = evento.testi[0];
                }

                return testo;

            },
        }
    }
</script>

<style scoped>

</style>
