<template>
    <base-scheda-attributo titolo="Links">

        <h-col v-if="model.links && model.links.length" cols="11" sm="10">
            <h-row v-for="(link, index) in model.links" :key="index">
                <h-col cols="12">
                    <a style="word-break: break-all;" :href="link.URI" target="_blank">{{ link.NOME }}</a>
                </h-col>
            </h-row>
        </h-col>

        <no-data-available v-else type="links"></no-data-available>

    </base-scheda-attributo>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseSchedaAttributo from '@/components/aaaProject/scheda/BaseSchedaAttributo';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';

    export default {
        name: 'SchedaEventoLinks',
        components: {HRow, HCol, NoDataAvailable, BaseSchedaAttributo},
        props: {model: Object}
    }
</script>

<style scoped>

</style>
