<template>
    <base-scheda-attributo titolo="Galleria">

        <h-col v-if="model.galleria && model.galleria.length" class="pt-0" cols="11" sm="10">

            <h-row justify="space-around" align="center">
                <h-col cols="auto" v-for="(galleria, index) in model.galleria" :key="index">
                    <base-galleria-evento :galleria="galleria"></base-galleria-evento>
                </h-col>
            </h-row>

        </h-col>

        <no-data-available v-else type="galleria"></no-data-available>

    </base-scheda-attributo>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseSchedaAttributo from '@/components/aaaProject/scheda/BaseSchedaAttributo';
    import BaseGalleriaEvento from '@/components/secure/eventi/components/BaseGalleriaEvento';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';

    export default {
        name: 'SchedaEventoGalleria',
        components: {HRow, HCol, BaseGalleriaEvento, NoDataAvailable, BaseSchedaAttributo},
        props: {model: Object}
    }
</script>

<style scoped>

</style>
