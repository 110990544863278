<template>
    <base-scheda-header models-name="eventi"
                        :model="model"
                        model-ref="l'evento"
    >

        <template #top>
            <v-spacer></v-spacer>
            <h-col class="pt-sm-0 pb-sm-1 pr-sm-4" cols="12" sm="auto">
                <base-select-lingue :value="value"
                                    @finish="$emit('finish', $event)"
                                    @input="$emit('input', $event)"
                ></base-select-lingue>
            </h-col>
        </template>

    </base-scheda-header>
</template>

<script>
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseSchedaHeader from '@/components/aaaProject/scheda/BaseSchedaHeader';
    import BaseSelectLingue from '@/components/aaaProject/table/api/BaseSelectLingue';

    export default {
        name: 'SchedaEventoHeader',
        components: {BaseSelectLingue, BaseSchedaHeader, HCol},
        props: {model: Object, value: Number}
    }
</script>

<style scoped>

</style>
